import { render, staticRenderFns } from "./NewPaintings.vue?vue&type=template&id=ca4fa010&scoped=true&"
import script from "./NewPaintings.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NewPaintings.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./NewPaintings.vue?vue&type=style&index=0&id=ca4fa010&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca4fa010",
  null
  
)

export default component.exports